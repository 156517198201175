import React from 'react';
import styled, {keyframes} from 'styled-components';
import { Helmet } from 'react-helmet';
import svg from './assets/image/__2.png';
import inst from './assets/image/Instagram-logo.png'

const App = () => (
    <Wrapper>
      <Helmet>
        <title>ANASTASIYA BEAUTY STUDIO - Prodlužování a barvení vlasů v Ostravě</title>
        <meta
            name="description"
            content="ANASTASIYA BEAUTY STUDIO v Ostravě, soukromý kosmetický salon, nabízí profesionální prodlužování vlasů, barvení, prodej kvalitních vlasů, laminaci a korekci obočí. Školení a individuální přístup zaručen. Objednejte se nyní."
        />
        <meta
            name="keywords"
            content="prodlužování vlasů Ostrava, barvení vlasů Ostrava, ANASTASIYA BEAUTY STUDIO, soukromý kosmetický salon, korekce obočí Ostrava, laminace obočí, školení prodlužování vlasů"
        />
      </Helmet>

      <p style={{ display: "none" }}>Nabízíme profesionální prodlužování vlasů, barvení a péči o obočí v Ostravě. Nejlepší kosmetické studio s individuálním přístupem ke každému klientovi.</p>
      <section style={{ display: "none" }}>
        <h2>O nás</h2>
        <p>
          Moje ANASTASIYA BEAUTY STUDIO je soukromý kosmetický salon v Ostravě, Vitkovice, kde přijímám pouze objednané klienty. Každá návštěva je zaměřena na maximální pohodlí a individuální přístup. Nabízím širokou škálu služeb včetně prodlužování vlasů, barvení, laminace a korekce obočí.
        </p>
        <h2>Naše služby</h2>
        <p>
          Specializuji se na prodlužování vlasů za tepla a za studena, používám nejnovější techniky z Itálie a Španělska. Dále nabízím prodej kvalitních vlasů, barvení na míru a vytváření culíků. Po naší proceduře můžete očekávat nejen krásný účes, ale i střih a profesionální péči o vlasy.
        </p>
        <h2>Školení</h2>
        <p>
          Poskytuji školení v oblasti moderních technik prodlužování vlasů, včetně nanokapslí a mikrokeratinu. Program školení zahrnuje teoretickou a praktickou část, kde se naučíte všechny potřebné dovednosti pro práci v tomto oboru.
        </p>
        <h2>Kde nás najdete</h2>
        <p>
          ANASTASIYA BEAUTY STUDIO se nachází v Ostravě, Vitkovice 70300, na adrese Prokopa Velikého 606/32. Pro objednání nás můžete kontaktovat na čísle +420771236124 nebo přes WhatsApp. Sledujte nás také na Instagramu: a_beautystudio_ostrava.
        </p>
      </section>
      <section>
        <H1>Vítejte v ANASTASIYA BEAUTY STUDIO!</H1>
        <Image src={svg} alt="Beauty Studio" />
        <P>Prodlužování vlasů, </P><P>které nikdo nepozná a vy je ani nepocítíte.</P>

        <Button>
          <Link href={'https://anastasiya-beuaty-studio.tilda.ws/'}>Přejít na webové stránky →</Link>
        </Button>
        <InstagramLink href={'https://www.instagram.com/anastasiya_studio_ostrava?igsh=dGVyYTJ2a295Zm5j&__coig_challenged=1'}>
          <InstagramIcon src={inst} alt="Instagram" />
        </InstagramLink>
      </section>
    </Wrapper>
);
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 100vh;
  font-family: Arial, sans-serif;
  overflow: hidden;
  background: linear-gradient(to bottom, #c0bbb5, #5e5855); 

  @media (max-width: 375px) {
    font-size: 0.8em;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
  }
`;

const H1 = styled.h1`
  font-size: 2.5em;
  margin: 20px;
  
  @media (max-width: 400px) {
    font-size: 1.5em;
  }
`;

const P = styled.p`
  font-size: 1.5em;
  margin: 10px;
`;

const Button = styled.button`
  background-color: #d94a3a;
  color: white;
  font-family: Arial, sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin: 20px;
  border: none;
  animation: ${pulseAnimation} 2s infinite;
  height: 60px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #ef2736;
    transform: scale(1.1);
  }

  &:active {
    background-color: #ef2736;
    transform: scale(1.05);
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const InstagramLink = styled.a`
  display: block;
  margin: 20px;
`;

const InstagramIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export default App;